/////*          */////
/////*  PTRS    */////
/////*          */////

.btn {
margin-right: .5rem;
}

/* Colours */
$white:             #FFFFFF;
$primary:           #233A9E;
$primaryShade:      rgba($primary, .05);
$secondary:         #5F1C07;
$darkBlue:          #1A2552;
$darkGreen:         #4B8515;
$darkGreenShade:    rgba($darkGreen, .1);
$fadeGreen:         #edf7ed;
$darkGrey:          #6D6E70;
$darkGreyShade:     rgba($darkGrey, .5);
$greyBorder:        #DDDDDD;
$background:        #F5F5F5;
$igaBlue:           #022d42;
$igaDarkBlue:       #022C41;
$igaTeal:           #347C87;
$mygGreen:          #003c40;
$mygGreen2:         #00ff00;
$disabled:          #F7F7F7;
$alertError:        #FF1100;
$alertInfo:         #2196f3;
$alertWarning:      #ff9800;
$alertSuccess:      #4caf50;
$default:           #333333;
$helpText:          rgba($default, .9);
$dataGridHeader: #e3f2fd;
$dataGridHeaderShade: #e3f2fd4a;
$dataGridError: #611A15;

a {
    cursor: pointer;
}

.white {
    color: $white
}

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6 {
    color: $default;
    letter-spacing: -0.02em !important;
}

h1 {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 700 !important;
    letter-spacing: -0.05em !important;

    &::after {
        height: 5px;
        width: 300px;
        background-color: $primary;
        content: "";
        left: 0;
        bottom: 0;
        display: block;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
}

.MuiTypography-h4 {
    font-size: 1.75rem !important;
}

.homePageh4 {
    font-size: 2.5rem !important;
    color: $default;    
    letter-spacing: -0.02em !important;
}

/* Accessibility */
.MuiButtonBase-root {
    outline: focus-ring-color auto 1px !important;
}

.MuiFormHelperText-root.Mui-error {
    color: #FF0000 !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #FF0000 !important;
}

.MuiOutlinedInput-root {
    background-color: #FFF;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: scale(0.9);
}

/* Spinner */
    .spin-wrapper {
        position: relative;
        width: 100%;
        height: 50vh;
        background: none;

        .spinner {
            position: absolute;
            height: 60px;
            width: 60px;
            border: 3px solid transparent;
            border-top-color: $primary;
            top: 50%;
            left: 50%;
            margin: -30px;
            border-radius: 50%;
            animation: spin 1s linear infinite;

            &:before, &:after {
                content: '';
                position: absolute;
                border: 3px solid transparent;
                border-radius: 50%;
            }

            &:before {
                border-top-color: $secondary;
                top: -12px;
                left: -12px;
                right: -12px;
                bottom: -12px;
                animation: spin 2s linear infinite;
            }

            &:after {
                border-top-color: $darkGreen;
                top: 6px;
                left: 6px;
                right: 6px;
                bottom: 6px;
                animation: spin 3s linear infinite;
            }
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
    /* Buttons */
    button.MuiButton-outlinedPrimary:hover {
        color: #FFF;
        background-color: $primary;
    }

    button.MuiButton-outlinedPrimary {
        color: $primary;
        border: 1px solid $primary;
    }

    button.MuiButton-contained {
        box-shadow: none !important;
    }

    button.MuiButton-containedPrimary:hover {
        background-color: $darkGreen;
        color: white;
    }

    button.MuiButton-containedPrimary {
        background-color: $primary;
    }

    button.MuiButton-containedSecondary:hover {
        background-color: $darkGreyShade !important;
    }

    .MuiButton-containedSecondary {
        background-color: $darkGrey !important;
    }
    /* Landing Page */
    .MuiPaper-outlined:hover {
        border: 1px solid $primary;
    }
    /* Account / Logout */
    button#account-button {
        padding: 0.5rem;
        border: 1px solid transparent;
        border-radius: 0.5rem;

        &:hover,
        &:focus {
            border-color: #FFF;
        }

        & span.account-email {
            color: #FFFFFF;
        }

        & span.MuiTouchRipple-child {
            background-color: transparent !important;
        }
    }

    #simple-menu {
        top: 3rem !important;

        & ul.MuiList-padding {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    /* Borders & Co*/
    hr {
        border: 0;
        height: 0;
        width: 100%;
        box-sizing: content-box;

        &.divider {
            border-top: 1px dotted $primary;
            margin: 2rem 0;
        }
    }
    /* Layouts */
    .jumbo {
        background-color: white;
        border: 1px solid #DDD;
    }
    /* Home Page*/
#homePage {
    h3 {
        color: $igaBlue;
    }

    button.MuiButton-containedPrimary {
        background-color: $mygGreen !important;
        width: 100%;
        max-height: 5rem;
        border-radius: 1rem;
    }

    button.MuiButton-containedPrimary#logIn {
        border-radius: 5px;
        background-color: black !important;
        padding: 12.5px 0px;
        text-transform: capitalize;
        font-size: 18px;
        color: #ffffff;
        font-family: sans-serif;
        margin-bottom: 18px;

        img {
            width: 40px;
            vertical-align: middle;
            padding-right: 7px;

            @media (max-width: 1028px) {
                display: block;
                margin: auto;
            }
        }

        span {
            display: inline-block;
        }

        &:hover, &:focus {
            background-color: #333 !important;
        }

        @media (max-width: 1028px) {
            width: 150px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            display: block;
            max-height: 170px;
        }
    }

    #digitalIdentityContent {
        color: #313131;
        text-align: center;
        font-family: sans-serif;

        a {
            color: #00698F;
            text-decoration: underline;
        }
    }
}

    .downloadWrapper {
        text-align: center;
        border-right: 1px dotted $default;
        &.last {
            border: none;
        }
    }

    /* Cards */
    .MuiIconButton-root {
        border: 1px solid #CCC;
    }
    /* Helpers*/
    .hidden {
        display: none;
    }

    .bold {
        font-weight: bold !important;
        letter-spacing: -0.03em !important;
    }

    .fullWidth {
        width: 100%;
    }

    .alignRight {
        text-align: right;
    }

    .alignCenter {
        text-align: center;
    }

    .fitContent {
        max-width: fit-content !important;
    }

    .bgWhite {
        background-color: #FFFFFF;
    }
    /* Breadcrumbs */
    #breadcrumbs {
        background-color: $primary;
        margin-bottom: 3rem;

        & nav,
        & p {
            font-size: 0.8rem !important;
            color: white;
        }

        & a:hover {
            text-decoration: none;
        }

        & a {
            text-decoration: underline;
        }
    }
    /* Stepper */
    .MuiFormLabel-root {
        color: rgba(0, 0, 0, 0.8) !important;
    }

    span.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel > svg {
        width: 2em !important;
        height: 2em !important;
    }

    .MuiStepConnector-alternativeLabel {
        top: 1.5em !important;
        left: calc(-50% + 2em) !important;
        right: calc(50% + 2em) !important;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $primary !important;
    }

    .MuiStepIcon-root.MuiStepIcon-completed {
        color: $darkGreen !important;
    }

    .MuiStepButton-root:hover .MuiStepIcon-root {
        color: #3f51b5 !important;
    }
    
    .MuiInputBase-input.Mui-disabled {
        background: $disabled;
        color: #333;
    }

    .MuiIconButton-colorSecondary:hover,
    .MuiCheckbox-colorSecondary:hover {
        background-color: $primaryShade !important;
    }

    .MuiRadio-colorSecondary.Mui-checked,
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: $primary !important;
    }

    .industryCode > .MuiBox-root,
    .state > .MuiFormControl-root,
    .country > .MuiBox-root {
        margin: 0 !important;
    }

    .country > .MuiBox-root {
        background-color: $disabled;
    }

    .formHelpText {
        color: $helpText !important;
        white-space: pre-line;
        line-height: 1.5em !important;
        padding-bottom: 1em !important;
        font-size: 0.85em !important;

        & button {
            margin-left: 0.2em;
            text-transform: capitalize;
            border: 1px solid #CCC;
            padding: 0em 1em;
            font-size: 0.75em !important;

            &:focus {
                outline: 2px solid #000000 !important;
            }
        }
    }

    .MuiCollapse-wrapper {
        padding: 0 0 0 1em;
        margin-bottom: 1em;
        border-left: 1px dotted $helpText;
    }

    label {
        white-space: pre-line;
    }

    .helpButton {
        display: contents;
    }
    /* Alerts */
    .MuiAlertTitle-root {
        margin-top: 0.5rem !important;
    }

    .MuiAlertTitle-root {
        font-size: 1.25rem !important;
    }

    .MuiAlert-message ul {
        padding-left: 2rem !important;
    }

    .MuiAlert-icon {
        font-size: 3rem !important;
    }

    .MuiAlert-standardError {
        border-color: $alertError;
        border-style: solid;
        border-width: 1px;
        border-left-width: 7px;
    }

    .MuiAlert-standardSuccess {
        border-color: $alertSuccess;
        border-style: solid;
        border-width: 1px;
        border-left-width: 7px;
    }

    .MuiAlert-standardInfo {
        border-color: $alertInfo;
        border-style: solid;
        border-width: 1px;
        border-left-width: 7px;
    }

    .MuiAlert-standardWarning {
        border-color: $alertWarning;
        border-style: solid;
        border-width: 1px;
        border-left-width: 7px;
    }

    /* Dialogs */
    .MuiDialogTitle-root {
        background-color: $primary;
        & > .MuiTypography-root {
            color: #FFFFFF;
        }
    }

    .MuiDialogActions-root {
        background-color: $primaryShade;
    }

/* Cards */
.MuiIconButton-root:hover,
.MuiIconButton-root:focus {
    //background-color: rgba($primaryShade, 1) !important;
    //color: white !important;
    //outline: none !important;
}

    .MuiIconButton-root {
        //background-color: rgba(0, 0, 0, 0.05) !important;
    }

    .cardButton:focus {
        outline: 5px auto $primary;
    }

    .cardButton,
    .cardButton > a {
        text-decoration: none !important;

        &:focus {
            outline: 5px auto $primary;
        }
    }

.cardLink {

    &.disabled {
        cursor: not-allowed;
        border: none;
        background-color: lightgray;
        color: #000;
        opacity: 0.75;

        & h3 {
            color: #000;
        }

        &:hover {
            border-color: inherit;
        }
    }

    & div.MuiCardContent-root {
        text-align: left;
    }

    &:hover {
        border-color: $primary;
        cursor: pointer !important;
    }

    & span.MuiIcon-root {
        overflow: visible;

        & svg {
            font-size: 3rem;
            color: rgba(0, 0, 0, 0.2);
        }

        & svg.disabled {
            color: rgba(0, 0, 0, 0);
        }
    }

    & div.cardIcon {
        text-align: right;
        margin-top: 2rem;
    }
}
    .MuiCardContent-root:last-child {
        padding-bottom: 1rem;
    }

/* Datagrids */
.reactDataTable {
    border: 1px solid $greyBorder;
    border-radius: 4px;
}

    .validation,
    .reactDataTable.validation {
        color: $dataGridError;
    }

    .validationWrapper {
        border: 2px dashed $dataGridError;
        padding: 1.5rem;
    }

    .validationIcon {
        margin: 0 0 -0.5rem 0;
    }

    button.ptrEOFY {
        color: $primary;
        border: 1px solid $primary;
        font-size: 0.7rem;
        padding: 2px 0;
        margin-left: 0.5rem;
        &.error {
            color: $dataGridError;
            border: 1px solid $dataGridError;
            padding: 2px 1rem;

        }
    }

    button.ptrStartDate {
        color: $primary;
        border: 1px solid $primary;
        font-size: 0.7rem;
        padding: 2px 0;
        margin-left: 0.5rem;

        &.error {
            color: $dataGridError;
            border: 1px solid $dataGridError;
            padding: 2px 1rem;
        }
    }

    .resubmit {
        color: $primary;
        border: 1px solid $primary;
        font-size: 0.7rem;
        padding: 6px 16px;
        margin-left: 0.5rem;
        text-transform: uppercase;
        font-weight: 500;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-decoration: none;
        cursor: pointer;
    }

    button.files {
        color: $darkGrey;
        border: 1px solid $darkGreyShade;
        font-size: 0.8rem;
        padding: 0.25rem 0.5rem;
        margin: 0 0.5rem 0.5rem 0;
        text-transform: inherit;

        &.Mui-disabled {
            color: $darkGrey;
        }
    }

    .headEntity > .MuiGrid-item {
        &.last {
            border-right: none;
        }

        color: $primary;
        padding: 1em 4em 1em 2em;
        border-right: 1px solid #CCC;
    }

    .dataTable {
        width: 100%;
        background: #FFF;
    }

    .validation .rdt_TableCol {
        color: $dataGridError !important;
    }

    .rdt_Table,
    .rdt_TableRow,
    .rdt_TableCol {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 0.95rem !important;
    }

    .MuiDataGrid-colCell,
    .rdt_TableCol
    {
        color: $primary !important;
    }


    .validation .rdt_TableHeadRow {
        background-color: #fdecea !important;
    }

    .MuiDataGrid-columnsContainer,
    .rdt_TableHeadRow {
        background-color: $dataGridHeader !important;
    }


    .rdt_TableCell > input[type="checkbox"] {
        font-size: 0.95rem !important;
    }

    .rdt_ExpanderRow > .rdt_TableHeader,
    .rdt_TableHeader {
        display: none !important;
    }

    .rdt_TableCol_Sortable:hover {
        color: inherit !important;
    }

    .MuiDataGrid-root .MuiDataGrid-iconSeparator
    {
        color: rgba($primary, .5) !important;
    }

    .MuiDataGrid-columnSeparator,
    .MuiDataGrid-iconSeparator {
        display: none !important;
    }

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
    background-color: $dataGridHeaderShade !important;
    color: $primary;
}


/* Tabs */
.MuiTabs-root {
    background-color: $background;
}

.MuiTabs-root .Mui-selected {
    background-color: #FFF;
    border: 1px solid $greyBorder;
}

/* MATERIAL UI Bugfixes*/
.MuiInputLabel-outlined { // Input text labels show line-through.
    background: #FFF;
    border-left: 5px solid #FFF;
    border-right: 5px solid #FFF;
}

legend[class^="PrivateNotchedOutline"] {
    //  min-width: 5rem;
}


/* SingleFileUpload component */
.singleFileUpload {
    position: relative;
    border-style: dashed !important;
}

.singleFileUpload:hover, .singleFileUpload.dragActive, .singleFileUpload.hasFile, .singleFileUpload:focus-within {
    border: solid 1px $primary !important;
}

.singleFileUpload:hover .MuiAvatar-circle, .singleFileUpload.dragActive .MuiAvatar-circle, .singleFileUpload.hasFile .MuiAvatar-circle {
    background-color: $primary;
}

.singleFileUpload.error:hover .MuiAvatar-circle, .singleFileUpload.error.dragActive .MuiAvatar-circle, .singleFileUpload.error .MuiAvatar-circle {
    background-color: $alertError !important;
}

.singleFileUpload.noFile {
    cursor: pointer;
}

.dropZone {
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 1;
}

.singleFileUpload input[type="file"] {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 1;
}


.singleFileUpload .MuiCircularProgress-root {
    color: $primary;
    left: 11px;
    position: absolute;
    top: 11px;
    z-index: 1;
}

.singleFileUpload.error {
    border-color: $alertError !important;
}

.singleFileUpload .error-message {
    color: $alertError;
}