// @import 'node_modules/bootstrap/scss/bootstrap.scss';
// @import 'globalstyles/variables';
// @import 'globalstyles/layout';
// @import 'globalstyles/buttons';
// @import 'globalstyles/alerts';
// @import 'globalstyles/forms';
// @import 'globalstyles/customselect';
// @import 'globalstyles/navigation';
// @import 'globalstyles/modals';
@import 'ptrs.scss';
@import 'bootstrap-spacing.scss';

// gradiants

html, body {
    height: 100vh;
    color: $default !important;
    margin: 0 !important;
}

// Your customize CSS
body {
    padding: 0 !important;
}

#root {
    height: 100vh;
    // display: flex;
    flex-direction: column;
    align-items: stretch;
}

main {
    background-color: $background;

    &::after,
    &::before {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
}

// Header section

// govau header
.headergovau {
    background-color: #233A9E;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    position: relative;
    font-size: 0.75rem;
    line-height: 1;
    padding: 0 0.75rem;
}

.headergovau__bar {
    padding: 0;
    max-height: 0;

    a {
        color: black;
    }
}

@media only screen and (min-width: 576px) {
    .headergovau__bar {
        text-align: left;
    }
}

.headergovau__bar a:focus {
    outline: 2px solid #C390F9;
    outline-offset: 2px;
}

.headergovau__bar a::-moz-focus-inner {
    border: 0;
}

.headergovau__button:hover {
    text-decoration: none;
}

.headergovau__publisher:hover {
    text-decoration: none;
}

/* Notification Header */
.headerNotification {
    color: #313131;
    background-color: #efefef;
    padding: 10px 0 10px 0;
    font-family: "Open Sans", sans-serif;
    text-align: center;

    a {
        color: #00698f;
        border-bottom: 1px solid;
        text-decoration: none;

        &:hover, &:focus {
            background: #004a7f;
            color: #fff;
            outline: none;
        }
    }
}

/* Service Header */
.serviceHeader {
    //background-image: linear-gradient(to bottom, $igaDarkBlue, $igaTeal);
    background-color: $primary;
    padding: 1rem;
    vertical-align: middle;
}

@media only screen and (min-width: 768px) {
    .serviceHeader {
        padding: 1.5rem;
    }
}

.ptrs-crest {
    max-width: 100%;
    max-height: 100%;
}

.warning-icon {
    padding-right: 15px;
}

.account-icon {
    height: 38px;
    width: 38px;
    padding-left: 6px;

    @media (min-width: 576px) {
        height: 44px;
        width: 44px;
    }
}

.account-email {
    color: black;
    display: none;

    @media (min-width: 768px) {
        display: inline;
    }
}

.rdti-mobile-logo {
    height: 12px;
    width: 104px;

    @media only screen and (min-width: 576px) {
        height: 16px;
        width: 122px;
    }
}

.dropdown-menu.show>a.account {
    padding: 0;
}

// navbar
.navbar {
    padding: 0;
    margin: 0;
    
    li.active {
        box-shadow: 0;
        a {
            color: #474747 !important;
            font-weight: 800;
        }
    }

    a.nav-link {
        padding: 0.75rem 0 0.75rem 1rem;

        &:hover {
            background-color: #d5deec;
            color: #254f90;
        }
    }

    @media only screen and (min-width: 576px) {
        li.active {
            box-shadow: inset 0 -4px 0 #BFC6CC;
        }
    }

    @media only screen and (min-width: 768px) {
        margin: 0;

        .nav-item {
            min-width: 100px;
            text-align: center;
            line-height: 52px;
        }

        a.nav-link {
            padding: 0;
            border: 0;
            border-radius: 2rem;
        }
    }
}

.dropdown-toggle::after {
    display: none;
}

.navbar-toggler {
    font-size: 48px;
    display: inline-block;
    width: 1em;
    height: 1em;
    padding: 0 0.5rem;
    cursor: pointer;
    transition: transform .2s ease-in-out;
    vertical-align: middle;
    border: 0 none;
    background: transparent;
    &::-moz-focus-inner {
        padding: 0;
        border: 0 none;
    }
    &:focus {
        outline: 0;
    }
    &:before, &:after {
        content: '';
    }
    &:before, &:after, > .navbar-toggler-icon {
        display: block;
        width: 100%;
        height: 4px;
        margin: 6px 0;
        transition: transform .2s ease-in-out;
        background: white;
    }

    /**
    * Styles for the active `.hamburger` icon
    */
    .active:before,
    .active .icon,
    .active:after {
        background: white;
    }
}

// .navbar-toggler-icon {
//     background-image: url("/images/icon-menu.svg");
//     width: 30px;
//     height: 30px;
// }

.navbar-brand {
    vertical-align: middle;
}

// footer
footer {
    font-family: "Open Sans", sans-serif;
    flex: 1;
    width: 100%;
    //background-image: linear-gradient(to bottom, $igaDarkBlue, $igaTeal);
    background-color: $primary;
    vertical-align: middle;
    color: white;
    padding: 3rem 0;
    text-align: center;

    & a {
        color: white !important;
    }

    & .linkDivider::after {
        content: " | ";
        padding: 0 1em;
    }
}

.reset-expansion-style {
    background: transparent !important;
    padding: 0 !important;
    border: none !important;

    &:before {
        content: none;
    }

    &:after {
        content: none !important;
    }

    display: none;
}

.MuiStepButton-root:hover .MuiStepIcon-root {
    color: #3f51b5 !important;
}
